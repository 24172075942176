import React from "react"
import DocumentTitle from "react-document-title"
import createReactClass from "create-react-class"
import * as shared from "auth/component/shared"
import * as client from "auth/client"
import * as form from "shared/validate"
import assign from "object-assign"
import * as userShared from "util/person"
import Code_, {codePattern} from "shared/klass-code"
import { ContinueWithClever, ContinueWithGoogle, ContinueWithClassLink } from "shared/oauth";
import {PrimaryOutlineButton} from "../../../components/buttons"
import Avatar from "../../../components/avatar"
import {Chip} from "../../../components/chips"
import {getAvatar} from "shared/avatar"

const hrStyles = {
  border: "none",
  backgroundColor: "#efefef",
  color: "#efefef",
  height: "2px",
  margin: "0 auto",
  maxWidth: "352px"
}

const createOAuthState = (code) => (
  !code ? null : btoa(`klass_code=${code}&operation=student/join-class`)
)

const Code = (props) => (
  <shared.Subscreen className="code">
    <div className="grid gap-y-4">
      <shared.Question>Please enter your class code.</shared.Question>
      <shared.Explanation>Enter the six-digit code (e.g. &quot;AAA111&quot;) your teacher provides to ensure you're joining the right class. Ask your teacher if you don't have one yet.</shared.Explanation>
    </div>
    <Code_ {...props} />
    <div className="grid gap-y-4">
      <shared.Button disabled={!props.allowContinue} onClick={props.completeCode} pressOnEnter={true}>
        Continue
      </shared.Button>
      <div className="overline-sm">OR</div>
      <ContinueWithClever disabled={!props.allowContinue} actionType="signup" state={createOAuthState(props.codeFromParams)} />
      <ContinueWithClassLink disabled={!props.allowContinue} actionType="signup" state={createOAuthState(props.codeFromParams)} />
    </div>
  </shared.Subscreen>
)

const Klass = ({klass, onCancel}) => (
  <div className="klass">
    <Chip className="hover:bg-neutral-20">
      <div className="flex gap-x-3 items-center h-full">
        <span>{`${klass.name}: ${klass.code}`}</span>
        <button onClick={onCancel}>
          <span className="sr-only">Cancel class code entered</span>
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <path
              d="M1.39819 9.35582C1.14819 9.35582 0.898192 9.25582 0.698192 9.05582C0.298191 8.68082 0.298191 8.03082 0.698192 7.65582L3.57674 4.75227L0.688426 1.83834C0.288426 1.46334 0.295313 0.819824 0.695313 0.444824C1.07031 0.0448245 1.71343 0.0383397 2.08843 0.438339L5.00174 3.32727L7.90786 0.446152C8.28286 0.0461522 8.93285 0.0461522 9.30785 0.446152C9.70785 0.821152 9.70785 1.47115 9.30785 1.84615L6.42674 4.75227L9.30769 7.65823C9.70769 8.03323 9.70769 8.68323 9.30769 9.05823C9.10769 9.25823 8.85769 9.35823 8.60769 9.35823C8.35769 9.35823 8.10769 9.25823 7.90769 9.05823L5.00174 6.17727L2.09819 9.05582C1.89819 9.25582 1.64819 9.35582 1.39819 9.35582Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </Chip>
  </div>
)

const withSignup = (Component, validateForm, defaultProps) =>
  createReactClass({
    getInitialState() {
      return {
        isUsernameUnique: null,
        username: null,
        password: null,
        language: "en",
        shake: false,
        submitting: false
      }
    },
    setName(name) {
      this.setState({name})
    },
    setUsername(username) {
      this.setState({username, isUsernameUnique: null})
    },
    setPassword(password) {
      this.setState({password})
    },
    setLanguage(e) {
      this.setState({language: e.target.value})
    },
    checkUniqueUsername(setError) {
      const {username} = this.state
      if (username) {
        client.validateUniqueUsername(username, (err, resp) => {
          if (err) {
            throw new Error("Unknown error on validating username:" + err)
          }
          this.setState({isUsernameUnique: resp.body})
        })
      }
      setError()
    },
    onComplete() {
      const state = this.state
      if (!form.anyErrors(validateForm(state))) {
        this.setState({submitting: true})
        this.props.onComplete(state, (newState) => {
          if (newState) {
            this.setState(assign({submitting: false}, newState))
          }
        })
      }
    },
    toggleShake(shake) {
      this.setState({shake})
    },
    render() {
      const state = this.state
      const errors = validateForm(state)
      const {showGoogle, userType} = defaultProps
      return (
        <shared.SignupForm
          disabled={form.anyErrors(errors) || state.submitting}
          onContinue={this.onComplete}
          submitting={state.submitting}
          shake={state.shake}
          showGoogle={showGoogle}
          userType={userType}
        >
          <Component
            {...assign({}, this.props, {
              setLanguage: this.setLanguage,
              setPassword: this.setPassword,
              setUsername: this.setUsername,
              setName: this.setName,
              checkUniqueUsername: this.checkUniqueUsername,
              signup: state,
              errors: validateForm(state),
              onComplete: this.onComplete,
              toggleShake: this.toggleShake
            })}
          />
        </shared.SignupForm>
      )
    }
  })

const Minor_ = ({setUsername, checkUniqueUsername, errors, setPassword, setLanguage, onComplete}) => (
  <div className="grid gap-y-6">
    <shared.UsernameInput errors={errors} setValue={setUsername} onBlur={checkUniqueUsername} customError={errors.isUsernameUnique} />
    <shared.PasswordInput errors={errors} setValue={setPassword} onEnter={onComplete} />
    <shared.SelectLanguage onChange={setLanguage} />
  </div>
)

const Minor = withSignup(Minor_, form.validateMinorStudentSignupForm, {showGoogle: false})

const Major_ = ({setName, setUsername, checkUniqueUsername, errors, setPassword, setLanguage, onComplete}) => (
  <div className="grid gap-y-6">
    <shared.NameInput
      errors={errors}
      setValue={setName}
      maxLength={80} // Hardcoded to match server spec: firstname = 40, lastname = 40. Better than nothing.
    />
    <shared.UsernameInput errors={errors} setValue={setUsername} onBlur={checkUniqueUsername} customError={errors.isUsernameUnique} />
    <shared.PasswordInput errors={errors} setValue={setPassword} onEnter={onComplete} />
    <shared.SelectLanguage onChange={setLanguage} />
  </div>
)

const Major = withSignup(Major_, form.validateMajorStudentSignupForm, {showGoogle: true, userType: "Student"})

const AskAge = ({allowContinue, underAge, setUnderAge, completeUnderAge}) => (
  <shared.Subscreen>
    <shared.Textbox>
      <shared.Question>How old are you?</shared.Question>
      <shared.Explanation>This helps protect your privacy.</shared.Explanation>
    </shared.Textbox>
    <div className="grid grid-cols-2 gap-x-4">
      {/* A null value for underAge suggests neither should be selected. */}
      <shared.Button variant="outline" selected={underAge === true} onClick={setUnderAge.bind(null, true)}>
        I'm 12 or Younger.
      </shared.Button>
      <shared.Button variant="outline" selected={underAge === false} onClick={setUnderAge.bind(null, false)}>
        I'm 13 or Older.
      </shared.Button>
    </div>
    <shared.Button pressOnEnter={true} disabled={!allowContinue} onClick={completeUnderAge}>
      Continue
    </shared.Button>
  </shared.Subscreen>
)

const renderAskSignupType = (props) => {
  return (
    <shared.Subscreen className="askSignupType">
      <shared.Textbox>
        <shared.Question>Do you have a class code?</shared.Question>
        <shared.Explanation>Your teacher may have given you a six digit code. If not, you can log in via Google Classroom.</shared.Explanation>
      </shared.Textbox>
      <shared.Button onClick={() => props.setSignupType(SignupType.CODE)} pressOnEnter={true}>
        Yes, I have a class code
      </shared.Button>
      <shared.Button onClick={() => props.setSignupType(SignupType.GOOGLE)} pressOnEnter={true}>
        No class code
      </shared.Button>
    </shared.Subscreen>
  )
}

const renderGoogleSignup = (props) => (
  <shared.Subscreen className="googleSignup">
    <shared.Textbox>
      <shared.Question>Google Sign in</shared.Question>
      <shared.Explanation>
        Your teacher may have set up your Banzai class with Google Sign-in — use the same Google account you use to log into Google Classroom!
      </shared.Explanation>
    </shared.Textbox>
    <ContinueWithGoogle actionType="login" />
    <hr style={hrStyles} />
    <a className="link-underline text-primary-1" onClick={() => props.setSignupType(SignupType.CODE)}>Never mind, I do have a code</a>
  </shared.Subscreen>
)

const renderCode = ({onSetCode, completeCode, state, onReset, onFetchedKlass, setSignupType}, hasErrorOverride) => (
  <Code
    codeFromParams={state.code}
    onSetCode={onSetCode}
    onFetchedKlass={onFetchedKlass}
    onCancel={onReset}
    allowContinue={state.klass !== null}
    completeCode={completeCode}
    setSignupType={setSignupType}
    customErrors={[
      [() => hasErrorOverride, () => <div>Oops, something went wrong. Please refresh the screen and try again.</div>],
      [
        (klass) => klass && klass.course_ids && klass.course_ids.find((e) => e === 1),
        (klass) => (
          <div>
            Oops, <strong>{klass.name}</strong> is a legacy class and has been locked.
          </div>
        )
      ]
    ]}
  />
)

const SignupType = {
  UNKNOWN: 0,
  GOOGLE: 1,
  CODE: 2
}

const ruleMachine = [
  [({signupType}) => signupType === SignupType.UNKNOWN, renderAskSignupType],
  [({signupType}) => signupType === SignupType.GOOGLE, renderGoogleSignup],
  [({signupType, completedCode}) => signupType === SignupType.CODE && !completedCode, renderCode],
  [
    ({klass, completedUnderAge}) => klass && !klass.under_age && !completedUnderAge,
    ({setUnderAge, completeUnderAge, state}) => {
      const {underAge} = state
      return <AskAge underAge={underAge} allowContinue={underAge !== null} setUnderAge={setUnderAge} completeUnderAge={completeUnderAge} />
    }
  ],
  [({klass, underAge}) => (klass && klass.under_age) || underAge, ({completeSignup, state}) => <Minor code={state.code} onComplete={completeSignup} />],
  [
    ({klass, underAge}) => klass && !klass.under_age && !underAge,
    ({completeSignup, state}) => (
      <Major code={state.code} onComplete={completeSignup} />
      // TODO: Code needed here to send to server for google oauth. Should be included in the declaration
      // of this component, but it has no knowledge of top level state, where code is held.
    )
  ],
  [() => true, (state) => renderCode(state, true)]
]

const initialState = (code) => ({
  // signupType: SignupType.UNKNOWN,
  // signupType: SignupType.GOOGLE,
  signupType: SignupType.CODE,
  code: code ? code : null,
  klass: null,
  completedCode: false,
  underAge: null,
  completedUnderAge: false
})

const conformString = (code) => (code ? code : "")

const conformLength = (code) => (code.length === 6 ? code : "")

const conformPattern = (code) => (codePattern.test(code) ? code : "")

const conformUpperCase = (code) => code.toUpperCase()

const conform = (code) => conformUpperCase(conformPattern(conformLength(conformString(code))))

export default createReactClass({
  getInitialState() {
    return assign(initialState(conform(this.props.params.code)), {
      // Test keys allow developer to jump to a spot in the wizard
      // signupType: SignupType.UNKNOWN,
      // signupType: SignupType.GOOGLE,
      // code: "AAA111",
      // klass: {
      //   name: "Test Class",
      //   code: "AAA111",
      //   under_age: false
      // },
      // completedCode: true,
      // underAge: false,
      // completedUnderAge: true
    })
  },
  setSignupType(signupType) {
    this.setState({signupType, completedCode: false, code: null}) // Everytime we set this, lets clear out the code
  },
  completeCode() {
    this.setState({completedCode: true})
  },
  onSetCode(code) {
    this.setState({code, klass: null}) // Whenever the code is changed, reset verification back to false.
  },
  onFetchedKlass(klass) {
    this.setState({klass: klass === "not-found" ? null : klass})
  },
  setUnderAge(underAge) {
    this.setState({underAge})
  },
  completeUnderAge() {
    this.setState({completedUnderAge: true})
  },
  completeSignup(user, signalFinished) {
    this.setState(assign({}, user), () => {
      const state = this.state
      const {username, name, password, language} = state
      const userToSubmit = {
        code: state.code,
        under_age: state.underAge,
        first_name: userShared.getFirstName(name),
        last_name: userShared.getLastName(name),
        username: username,
        password: password,
        language: language
      }
      client.completeStudentSignup(userToSubmit, (err, resp) => {
        // TODO: This new logic may need repeated in direct/signup
        if (err) {
          const {response} = err
          if (response && response.status === 422) {
            if (response.body.error === "not-unique") {
              signalFinished({isUsernameUnique: false})
            } else {
              throw new Error("Error on signup for user with 422: " + username)
            }
          } else {
            throw new Error("Unknown error on signup for user: " + username + "; error: " + JSON.stringify(response))
          }
        } else if (resp && resp.status === 200) {
          this.setState({submit: true})
          signalFinished() // Indicates no new state to communicate to the callback
        } else {
          throw new Error("Unknown error on signup for user, no response: " + username)
        }
      })
    })
  },
  onReset() {
    this.setState(Object.assign(initialState(), {signupType: this.state.signupType}))
  },
  render() {
    const {username, password, submit, klass, completedCode} = this.state
    const {code} = this.props.params
    const klassView = klass && completedCode && <Klass klass={klass} onCancel={this.onReset} />
    return (
      <DocumentTitle title="Sign Up Student: Banzai">
        <shared.Main>
          <div className="md2:flex md2:justify-center md2:items-center md2:gap-x-20 lg:gap-x-[7.5rem]">
            <div>
              <shared.Panel className="klass-code">
                {klassView}
                {shared.showView(ruleMachine, this)}
                <shared.AlreadyHaveAccount />
                <shared.HiddenLoginForm submit={submit} username={username} password={password} />
              </shared.Panel>
              <shared.Footer />
            </div>
            <div className="hidden md2:flex flex-col gap-y-6 -mt-20 items-center">
              <Avatar avatar={getAvatar("wink")} size="xl" />
              <div className="title">Already have an account?</div>
              <div>
                <PrimaryOutlineButton href="/auth#/login">Log In</PrimaryOutlineButton>
              </div>
            </div>
          </div>
        </shared.Main>
      </DocumentTitle>
    )
  }
})
